@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

/* START CSS VARIABLES */
:root {
    --body-background: #1f1f1f;
    --body-text: #ffffff;
    --container-background: #273349;
    --table-background: #1c223b;
    --table-background-hover: #151b31;
    --table-text: #e2e2e2;
    --pts-200: #d6a13f;
    --pts-300: #4480e7;
    --pts-600: #40c587;
    --delete-background: #242c4c;
    --delete-color: #909090;
    --delete-hover: #ff0000;
}
/* END CSS VARIABLES */


/* START CSS RESET + COMMON STYLES */
* {
    box-sizing: border-box;
}
body {
    animation: fadeInPage ease 1.5s; /* fade page in */
    animation-iteration-count: 1; /* fade page in */
    animation-fill-mode: forwards; /* fade page in */    
    justify-content: center;
    height: 100vh;
    background-color: var(--body-background);
    color: var(--body-text);
    /* font-family: 'Open Sans', sans-serif; */
    margin: 0;
}
h1 {
    text-align: center;
    margin: -2.5rem 0 0.625rem 0;
}
/* END CSS RESET + COMMON STYLES */


/* START OUTER CONTAINER STYLES */
.outer-container {
    background-color: var(--container-background);
    border-radius: 0.625rem;
    box-shadow: 0 5px 10px rgba(12,16,31,0.4);
    padding: 6.25rem;
    margin: auto;
    width: 90%;
    max-width: 62.5rem;
}

/* Start Table Styles */
table {
    color: var(--table-text);
    font-size: 0.875rem;
    padding: 0.625rem;
    width: 100%;
}
table th {
    font-size: 1rem;
}
table th, table td {
    padding: 0.9375rem;
    text-align: left;
}
table tbody tr {
    background-color: var(--table-background);
}
table tbody tr:hover {
    background-color: var(--table-background-hover);
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
}
table tbody tr td:first-of-type {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}
table tbody tr td:last-of-type {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    text-align: center;
}
table tbody tr.pts-200 td:first-of-type {
    border-left: 0.3125rem solid var(--pts-200);
}
table tbody tr.pts-300 td:first-of-type {
    border-left: 0.3125rem solid var(--pts-300);
}
table tbody tr.pts-600 td:first-of-type {
    border-left: 0.3125rem solid var(--pts-600);
}
table tbody tr td .fa-circle {
    transform: scale(0.7);
}
table tbody tr.pts-200 td .fa-circle {
    color: var(--pts-200);
}
table tbody tr.pts-300 td .fa-circle {
    color: var(--pts-300);
}
table tbody tr.pts-600 td .fa-circle {
    color: var(--pts-600);
}
.delete-btn {
    cursor: pointer;
    background-color: var(--delete-background);
    background-color: transparent;
    border: 0;
    border-radius: 0.125rem;
    color: var(--delete-color);
    font-size: 1.1rem;
    opacity: 0.2;
    padding: 0.3125rem 0.625rem;
}
.delete-btn:hover {
    color: var(--delete-hover);
}
.delete-btn:focus {
  outline: 0;
}
table tbody tr:hover .delete-btn {
    opacity: 1;
}
/* End Table Styles */
/* END OUTER CONTAINER STYLES */


/* KEYFRAMES */
/* Fade In Webpage Keyframe */
@keyframes fadeInPage {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* MEDIA QUERIES */
@media(max-width: 768px) {
    .outer-container {
        padding: 1.25rem;
    }
    h1 {
        margin: 0;
    }
}
@media(max-width: 668px) {
    table th:nth-of-type(3), table td:nth-of-type(3) {
        display: none;
    }
}
.mainContainer{
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../assets/img/homebl.jpg');
    background-size: contain;
    background-position: 0 40px;
    background-attachment: fixed;
}


.spbg{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* background-image: url("../../assets/img/LandingPageHeroBg.webp"); */
      background-size: contain;
      background-position: 0 40px;
    background-attachment: fixed;
      width: 100%;
      
     
  }
.container{
	margin: 0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container h1{
	color: white;
    font-size: 64px;
}

.content{
    margin-top: 50px;
    /* width: 100vw; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
