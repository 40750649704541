/*img:hover{
    /*animation:shake 0.5s;
     animation-iteration-count: infinite;*/

/* transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
/*}*/
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");



.hding h1{

  margin-top:140px;
  color: #ffffff;
  font-family: Poppins; 
}

.bdy{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.gllry{
    justify-content: center;
    align-items: center;
    padding-left: 10vw;
    padding-right: 10vw;
}
      .gallary23{
        padding: 10px;
        max-width: 1100px;
        margin: 0 auto;
     background-color: #ffffff28;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit , minmax(250px,1fr));
        grid-auto-rows: 250px;
        grid-auto-flow: dense;
        }
        .gallary23 div{
            box-shadow: 0 5px 10px rgba(255, 253, 253, 0.767);
        }
        .h_strech{
            grid-column:span 2 ;
        
        }
        
        .v_strech{
            grid-row: span 2;
        }
        
        .big_strech{
        grid-column: span 2;
        grid-row: span 2;
        }
        
        .gallary23 div img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        @media(max-width:560px)
        {
            .gallary23{
                grid-template-columns: 1fr;
            }
            .v_strech{
                grid-column: span 1;
                
            }
        
            .h_strech{
                grid-column: span 1;
            }
        
            .big_strech{
                
                grid-column: span 1;
            }
        }
 