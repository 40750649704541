.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 30px 10px 30px;
    border-radius: 15px;
    box-shadow: 3px 2px 3px 0px rgb(0 0 0 / 75%);
}

.imageWrap {
    position: relative;
    width: 357px;
    height: 320px;
    overflow: hidden;
}

.img__description {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(2, 0, 36);
    background: rgba(32, 32, 32, 0.4);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    margin: 10px 30px 10px 30px;
    transition: opacity 0.2s, visibility 0.2s;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.eventName {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 80px;
    word-wrap: break-word;
    max-width: 400px;
    text-align: center;
}

.imageWrap:hover .img__description {
    visibility: visible;
    opacity: 1;
}
