@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    font-family: 'Poppins', sans-serif;
}

.modalContent {
    max-width: 950px;
    max-height: 85vh;
    background-color: rgb(24,26,27);
    border-radius: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
	margin-top: 80px;
}

.modal_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: rgb(24,26,27);
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    padding-block: 35px;
}

.modal_head h1 {
	color: white;
    font-size: 30px !important;
    margin: 0;
}
.modal_head svg {
	margin-left: 40px;
	position: relative;
	bottom: calc(100% - 25px);
}

@media screen and (max-width: 950px) {
    .modalContent {
        padding: 0;
        border-radius: 15px;
        /* border-top-right-radius: 15px;
        border-top-left-radius: 15px; */
        margin-inline: 10px;
        padding-inline: 25px;
        transform: scale(0.8);
		margin-top: 40px;
    }
}

@media screen and (max-width: 754px) {
	.modalContent {
		transform: scale(0.7);
	}
}

@media screen and (max-width: 590px) {
    .modal_head h1 {
        font-size: 25px !important;
    }
}

.modal_body {
    padding: 10px;
    background-color: rgb(24,26,27);
    color: white;
    border-radius: 0 0 5px 5px;
    max-width: 600px;
    /* max-height: 60vh; */
}

.modal_footer {
    display: flex;
    width: 100%;
    justify-content: inherit;
    align-items: center;
    padding: 10px;
    background-color: rgb(24,26,27);
    border-radius: 0 0 5px 5px;
    height: 75px;
    overflow: hidden;
    padding-block: 47px;
}
.modal_register_button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 175px;
    min-height: 60px;
    margin: 10px;
    margin-left: 17px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    cursor: pointer;
    background-color: black;
    color: #ffffff;
    border: none;
    outline: none;
    text-decoration: none;
    padding: 10px 30px;
}

.rulebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 175px;
    min-height: 60px;
    margin: 10px;
    margin-right: 17px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 15px;
    cursor: pointer;
    background-color: #ffffff;
    color: #000000;
    border: 3px solid #000000;
    outline: none;
    text-decoration: none;
    padding: 10px 30px;
}


.modal_footer :nth-child(2) {
    background-color: #000000;
    color: #ffffff;
}

.date_loc {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    /* border: 4px dotted #8cc1ff; */
    width: 100%;
    border-radius: 30px;
    padding: 0px 15px 0px 15px;
}

.date_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.date_row img {
    height: 50px;
}
.date_text {
    font-size: 20px;
    font-weight: 200;
    margin-right: 8px;
}
.date_value {
    font-size: 20px;
    font-weight: 700;
    margin-right: 8px;
}
.date {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    margin: 3px;
    font-weight: 700;
}

.day {
    font-size: 25px;
    font-weight: 700;
}

.month {
    font-size: 25px;
    /* text-transform: uppercase; */
}

.location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.location img {
    height: 50px;
}

.description {
	color: black;
    font-size: 20px;
    font-weight: 100;
    padding: 5px 5px 20px 0px;
}

.team_pay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 200;
}

.team_pay a {
    color: white;
    text-decoration: none;
    position: relative;
}

.team_pay img {
    height: 40px;
    margin: 5px;
}

.team_pay div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contacts img {
    height: 40px;
    margin: 5px;
}

.contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
}

.contact a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0px;
    border: 3px dotted #ffffff;
    border-radius: 8px;
    padding: 5px;
    text-decoration: none;
    color: #ffffff;
    background-color: #000000;
}

.contact span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact img {
    height: 15px;
}

