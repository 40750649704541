@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.cd-logo {
	height: 100%;
	padding: 10px 0;
}

.nav-logo-verdant {
	margin-left: 30px;
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
	.nav-logo-verdant {
		display: none;
	}
}

.nav-desktop-primary {
	height: 100%;
	display: flex;
	flex-direction: row;
}

.nav-desktop-primary li {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 10vw;
	min-width: 100px;
	/* white-space: nowrap; */
	margin: 0 10px;
	overflow: visible;
}

.nav-desktop-primary a {
	display: flex;
	align-items: center;
	font-family: "Montserrat", monospace;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 700;
	height: 80px;
	color: white;
	text-align: center;
	transition: all 0.5s ease;
}

.nav-desktop-primary li:hover > a {
	transform: translateY(-2px);
	text-decoration: underline;
}

.nav-desktop-primary .nav-menu-opener {
	cursor: pointer;
	margin: 28.5px 0;
}

.nav-profile-button {
	width: fit-content;
}

.nav-desktop-secondary {
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: #1d1d1d;
	border-radius: 0 0 10px 10px;
	width: 15vw;
	min-width: 175px;
	height: fit-content;
	padding-bottom: 10px;
	justify-content: center;
	top: -1px;
}

.nav-desktop-secondary a {
	display: block;
	width: 100%;
	height: unset;
	padding: 10px;
}
.nav-fest:hover .nav-desktop-secondary {
  display: block;
}

.nav-primary-separator {
	width: 20px !important;
	min-width: unset !important;
  padding : 30px;
}

.nav-desktop-register {
	height: 50px !important;
	padding: 20px;
	border-radius: 16px;
	border: 1px solid white;
	background-color: white;
	color: black !important;
	margin: 15px 0;
}

.nav-desktop-register:hover {
	background-color: #1d1d1d;
	color: white !important;
}

.red-text {
	color: #ff7575 !important;
}

.grey-text {
	color: #fff8 !important;
}

.nav-mobile-primary {
	display: none;
}

.nav-mobile-opener {
	transition: all 0.25s;
	transform: translate(10px, -3px) rotate(-90deg);
}

@media screen and (max-width: 768px) {
	.nav-desktop-primary {
		display: none;
	}

	.nav-but-wrap {
		display: block;
	}

	.nav-mobile-primary {
		display: unset;
		background-color: #1f1f1f;
	}

	.nav-mobile-primary li {
		margin: 15px 0;
	}

	.nav-mobile-primary a {
		font-family: "Montserrat", monospace;
		letter-spacing: 1px;
		font-weight: 700;
		color: white;
		text-align: center;
		font-size: 20px;
	}

	.nav-mobile-primary a:hover {
		background-color: #0000 !important;
	}

	.nav-mobile-secondary {
		background-color: #3a3939;
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		height: fit-content;
		padding-bottom: 10px;
		justify-content: center;
		top: -1px;
	}

	.nav-desktop-register {
		width: 100%;
		border-radius: 0 !important;
		margin: 0;
	}
}
