
body {
    background-repeat: repeat-x;
    background-attachment: fixed;
    /* background-color: #232323; */
  }

  .head {
    /* color: rgb(157, 229, 22);
    font-weight: bold;
    text-align: center;
    font-size: 5rem; */
    color: #fff;
  
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    text-transform: uppercase;
  }

.landing_background_verdant {
	background-image: url("../../assets/img/homebl.jpg"); 
	background-size: cover;
	width: 100%;
	height: 100vh;
	overflow: auto;
}
.sec-title{
    position:relative;
    margin-bottom:60px;
    color: #fff;
    margin-top: 2%;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.6px;
    text-transform: uppercase;
}
.headBG {
    margin-top: 0;
    height: 309px;
    width: 100%;
    /* margin-left: 11%; */
    background-repeat: no-repeat;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.sec-title .title{
    position: relative;
    display: block;
    font-size: 28px;
    line-height: 24px;
    color: #fdda33;
    font-weight: 500;
    margin-bottom: 30px;
}

.sec-title h2{
    position:relative;
    display: inline-block;
    font-size:35px;
    line-height:1em;
    color:#212639;
    font-weight:400;
    padding-bottom: 30px;
    z-index: 2;
}


.sec-title.active h2:before{
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.sec-title h2:after{
    position: absolute;
    left: 0;
    bottom: 0px;
    height: 3px;
    width: 50px;
    content: "";
    background-color: #d1d2d6;
}

.sec-title.light h2{
    color: #ffffff;
}

.sec-title.light h2:after{
    opacity: .20;
    background-color: #ffffff;
}

.sec-title.text-center h2:before,
.sec-title.text-center h2:after{
    right: 0;
    left: 0;
    margin: 0 auto;
}

.sec-title.text-center h2:before{
    opacity: .60;
}

.speakers-section{
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 145px 0 90px;
    overflow:hidden;
	/* akhand */
    /* background: linear-gradient(-45deg, #883217, #ce134b, #23a6d5, #23d5ab); */
	/* background-size: 400% 400%; */
	animation: gradient 15s ease infinite;
    
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

.speaker-block{
    position: relative;
    margin-bottom: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}
@media only screen and (max-width: 600px) {
    .speaker-block{
        position: relative;
        margin-bottom: 40px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 330px;
        
    }
    .speaker-block .caption-box .name{
        position: relative;
        text-align: center;
        display: block;
        font-size: 15px;
        line-height: 1em;
        color: #ffffff;
        font-weight: 400;
        margin-bottom: 10px;
    }
  }

.speaker-block .inner-box{
    position: relative;
}

.speaker-block .image-box{
    position: relative;
    padding: 20px;
    background-color: rgba(255,255,255,.15);
    overflow: hidden;
    width: 330px;
}

.speaker-block .image-box .image:after,
.speaker-block .image-box:before{
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 100%;
    background-color: #ffffff;
    content: "";
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.speaker-block .inner-box:hover .image-box .image:after,
.speaker-block .inner-box:hover .image-box:before{
    height: 100%;
}

.speaker-block .image-box .image:after{
    opacity: .40;
    z-index: 1;
    top: auto;
    bottom: 0;
    background-color: #4a34a5;
}

.speaker-block .image-box .image{
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    overflow: hidden;
}

.speaker-block .image-box .image img{
    display: block;
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.speaker-block .social-links{
    position: absolute;
    left: 0;
    bottom: -140px;
    padding: 20px;
    width: 100%;
    z-index: 9;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

.speaker-block .social-links ul{
    position: relative;
    padding: 30px 20px;
    text-align: center;
    background-color: rgba(174, 65, 247, 0.85);
}

.speaker-block .social-links li{
    position: relative;
    display: inline-block;
    margin: 0 9px;
}

.speaker-block .social-links li a{
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.speaker-block .social-links li a:hover{
    color: #fac201;
}

.speaker-block .inner-box:hover .social-links{
    bottom: 0;
}

.speaker-block .caption-box{
    position: relative;
    padding-top: 30px;
}

.speaker-block .caption-box .name{
    position: relative;
    display: block;
    font-size: 30px;
    text-align: center;
    line-height: 1em;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 10px;
}

.speaker-block .caption-box .name a{
    color: #ffffff;
    display: inline-block;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.speaker-block .caption-box .name a:hover{
    color: #fdda33;
}

.speaker-block .caption-box .designation{
    position: relative;
    display: block;
    font-size: 17px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    opacity: .70;
}
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        img {
    max-width: 100%;
    height: auto;
}

