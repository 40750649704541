@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.Footer {
	width: 100%;
	/* position: relative; */
	margin-top: 200px;
	padding: 40px 0 20px 20px;
	background-color: #1d1d1d;
	/* margin-top: 20rem; */
}

.nav-item {
  	font-family: "Montserrat", monospace;

}


.Footer h3 {
	font-weight: 700;
	font-family: "Montserrat", monospace;
	letter-spacing: 2px;
	color: white;
}

.ft-1 p {
	color: white;
	padding: 0rem 5rem 0.6rem 0;
	font-weight: 500;
  	font-family: "Montserrat", monospace;

}


.row.align-items-start {
  align-items: flex-start; /* Align items at the top of the container */
}

.footerimg {
  max-width: 11%; /* Use 100% width for responsiveness */
  height: 35%; /* Allow the height to adjust accordingly */
  margin-bottom: 10px; /* Adjust margin as needed */
  margin-left: 10px; /* Adjust margin as needed */
}


.footerIcons i {
	padding: 0.2rem 0.5rem 0.4rem 0.5rem;
	/* background: #e1e1e1; */
	fill: whitesmoke;
	margin: 0 0.5rem;
	border-radius: 50%;
}

.footerIcons i:hover {
	background: #6da287;
	fill: #fff;
	transition: 0.6s;
	cursor: pointer;
}

.Footer h5 {
	color: #6da287;
}

.ft-2 ul {
	list-style: none;
	padding-left: 0;
}

.ft-2 ul li {
	padding: 0.35rem 0;
	font-weight: 500;
}

.ft-2 ul a {
	text-decoration: none;
	color: white;
	font-size: 1.06rem;
}

.ft-3 a {
	color: white;
}

.ft-3 p {
	color: white;
	font-weight: 500;
	padding: 0.1rem 0;
	font-size: 1.06rem;
}

.ft-3 i {
	fill: white;
	padding-right: 0.5rem;
	font-family: "Montserrat", monospace;
}
@media only screen and (max-width: 767px) {
  .Footer {
    padding: 20px 0; /* Adjust padding for small devices */
  }

  .ft-1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .ft-1 h3 {
    margin-bottom: 10px; /* Adjust margin as needed */
  }

  .footerimg {
    margin-bottom: 10px; /* Adjust margin as needed */
  }

  .ft-1 .footerIcons {
    margin-top: 10px; /* Adjust margin as needed */
  }
.ft-2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
}
